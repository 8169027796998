import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { NotificationsService } from '../../../core/notifications/notifications.service';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-notifications',
  imports: [TranslateModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
  protected showNotification = false;
  protected message: string | null = null;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.notificationsService
      .getNotificationObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((message) => {
        this.message = message;
        this.showNotification = message !== null;
        this.cd.detectChanges();
      });
  }

  closeNotification() {
    this.notificationsService.closeCurrentNotification();
  }
}
