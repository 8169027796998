import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { StoreService } from '../../core/store/store.service';
import { Observable } from 'rxjs';
import { AsyncPipe, Location } from '@angular/common';
import { AuthService } from '../../common/guards/auth.service';
import { WalletService } from '../../services/wallet.service';
import { version } from '../../../../package.json';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, RouterLink],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  protected readonly playerName: Observable<string | undefined>;
  protected version = version;
  protected showGoBackIcon = false;
  protected disableLogOutIcon = true;

  constructor(
    private storeService: StoreService,
    private readonly authService: AuthService,
    private readonly walletService: WalletService,
    private readonly location: Location,
    private readonly router: Router,
  ) {
    this.playerName = this.storeService.getPlayerName();
    this.updateIconsState();
  }

  ngOnInit(): void {
    this.updateIconsState();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateIconsState();
      });
  }

  goBack(): void {
    this.location.back();
  }

  logout() {
    this.walletService.disconnectWallet();
    this.authService.logout();
  }

  private updateIconsState(): void {
    const videoPage =
      this.router.url === '/app/wallet-list' ||
      this.router.url === '/app/wallet-connect';

    this.showGoBackIcon = videoPage;
    this.disableLogOutIcon = videoPage;
  }
}
