<div class="header">
  <div class="icon-container">
    @if (showGoBackIcon) {
      <a (click)="goBack()">
        <img
          class="icon go-back-icon"
          src="assets/images/go_back.svg"
          alt="Go Back"
          width="24" />
      </a>
    }
  </div>

  @if (playerName | async; as name) {
    <div class="user-id">{{ name }}</div>
  }

  <div class="icon-container">
    @if (!disableLogOutIcon) {
      <a (click)="logout()">
        <img
          class="icon logout-icon"
          src="assets/images/Vector.svg"
          alt="Logout"
          width="24" />
      </a>
    }
  </div>
</div>
<div class="safe-zone">
  <router-outlet />
</div>
