import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationsComponent } from '../../ui/notifications/notifications/notifications.component';
import { TranslateService } from '@ngx-translate/core';
import { setAppLocale } from '../../core/store/actions/settings.actions';
import { ConnectionService } from '../../services/connection.service';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { PlayersService } from '../../services/players.service';
import { AuthService } from '../../common/guards/auth.service';
import { version } from '../../../../package.json';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-init-settings',
  standalone: true,
  imports: [NotificationsComponent],
  template: ``,
})
export class InitSettingsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  constructor(
    private readonly store: Store,
    private readonly connectionService: ConnectionService,
    private readonly notificationService: NotificationsService,
    private readonly translate: TranslateService,
    private readonly playersService: PlayersService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.getPlayerModel();
    this.initLocale();
    this.watchInternetConnection();
    this.storeAppVersion();
  }

  getPlayerModel() {
    if (this.authService.isLoggedIn()) {
      this.playersService
        .getPlayerModel()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  private initLocale() {
    const browserLang = this.translate.getBrowserLang();
    const locale = browserLang?.match(/ru|en|es|pt/) ? browserLang : 'en';
    this.translate.use(locale);
    this.store.dispatch(setAppLocale({ locale }));
  }

  private watchInternetConnection() {
    this.connectionService.isOffline$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () =>
          this.notificationService.openNotification('NO_INTERNET_CONNECTION'),
      });
  }

  private storeAppVersion() {
    if (!localStorage.getItem('app_version'))
      localStorage.setItem('app_version', version);
  }
}
