import { createReducer, on } from '@ngrx/store';
import {
  clearPlayerId,
  setAddress,
  setBalance,
  setPlayerId,
  setPlayerWrapper,
} from '../actions/player.actions';
import { PlayerWrapper } from '../../../common/models/wrappers/player.wrapper';
import { Address } from 'viem';

export interface PlayerState {
  playerId: string | null;
  playerWrapper: PlayerWrapper | null;
  balance: string | null;
  address: Address | undefined;
}

export const initialState: PlayerState = {
  playerId: null,
  playerWrapper: null,
  balance: null,
  address: undefined,
};

const _playerReducer = createReducer(
  initialState,
  on(setPlayerId, (state, { playerId }) => ({
    ...state,
    playerId,
  })),
  on(setPlayerWrapper, (state, { playerWrapper }) => ({
    ...state,
    playerWrapper,
  })),
  on(setBalance, (state, { balance }) => ({
    ...state,
    balance,
  })),
  on(setAddress, (state, { address }) => ({
    ...state,
    address,
  })),
  on(clearPlayerId, (state) => ({ ...state, playerId: null })),
);

export function playerReducer(state: PlayerState | undefined, action: any) {
  return _playerReducer(state, action);
}
