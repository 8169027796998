import {
  provideRouter,
  Router,
  Routes,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { authInterceptor } from '../common/interceptors/auth.interceptor';
import { errorInterceptor } from '../common/interceptors/error.iterceptor';
import { loadingInterceptor } from '../common/interceptors/loading.interceptor';
import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import * as Sentry from '@sentry/angular';
import { provideStore } from '@ngrx/store';
import { playerReducer } from './store/reducers/player.reducer';
import { settingsReducer } from './store/reducers/settings.reducer';
import { LocalStorageTranslateLoader } from './translate/local-storage-translate-loader';
import { version } from '../../../package.json';
import { provideLottieOptions } from 'ngx-lottie';

registerLocaleData(localeEn);
registerLocaleData(localeRu);
registerLocaleData(localeEs);
registerLocaleData(localePt);

export function createTranslateLoader(httpClient: HttpClient) {
  return new LocalStorageTranslateLoader(httpClient);
}

const SENTRY_URL = environment.sentryUrl;
Sentry.init({
  dsn: SENTRY_URL,
  release: version,
  environment: environment.sentryEnv,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: environment.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
});

export interface CoreOptions {
  routes: Routes;
}

export function provideCore({ routes }: CoreOptions) {
  return [
    provideHttpClient(
      withInterceptors([authInterceptor, errorInterceptor, loadingInterceptor]),
    ),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          allowedDomains: ['localhost:3000'],
          disallowedRoutes: [
            'localhost:3000/authenticate',
            'localhost:3000/login',
          ],
        },
      }),
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimationsAsync(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideStore({
      player: playerReducer,
      settings: settingsReducer,
    }),
    { provide: LOCALE_ID, useValue: 'en-Us' },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ];
}
