import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { inject, Injector } from '@angular/core';
import { NotificationsService } from '../../core/notifications/notifications.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const injector = inject(Injector);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const notification = injector.get(NotificationsService);

      if (
        error.error instanceof ErrorEvent &&
        error.error.message === 'Network error'
      ) {
        notification.openNotification('NO_INTERNET_CONNECTION');
      } else if (error.status === 0) {
        notification.openNotification(
          'NO_INTERNET_CONNECTION_OR_SERVER_UNREACHABLE',
        );
        return of();
      } else if (error.status === 503) {
        notification.openNotification('SERVER_UNAVAILABLE');
      } else {
        if (error.status === 400) {
          console.error('HTTP Error:', error);
        }
        if (error.status === 500) {
          notification.openNotification('SERVER_ERROR');
        }
      }
      return throwError(() => error);
    }),
  );
};
