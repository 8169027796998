import { createReducer, on } from '@ngrx/store';
import {
  setAppLocale,
  setMaxWithdrawValue,
  setMinWithdrawValue,
} from '../actions/settings.actions';

export interface SettingsState {
  minWithdrawValue: number | null;
  maxWithdrawValue: number | null;
  locale: string;
}

export const initialState: SettingsState = {
  minWithdrawValue: null,
  maxWithdrawValue: null,
  locale: 'ru',
};

const _settingsReducer = createReducer(
  initialState,
  on(setMinWithdrawValue, (state, { minWithdrawValue }) => ({
    ...state,
    minWithdrawValue,
  })),
  on(setMaxWithdrawValue, (state, { maxWithdrawValue }) => ({
    ...state,
    maxWithdrawValue,
  })),
  on(setAppLocale, (state, { locale }) => ({
    ...state,
    locale,
  })),
);

export function settingsReducer(state: SettingsState | undefined, action: any) {
  return _settingsReducer(state, action);
}
