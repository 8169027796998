import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { AuthService } from '../guards/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService) as AuthService;

  if (req.url.includes('/auth') || req.url.includes('/settings')) {
    return next(req);
  }

  const addToken = (request: HttpRequest<any>) => {
    const token = localStorage.getItem('access_token');
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  };

  if (authService.isTokenExpired()) {
    return authService.refreshToken().pipe(
      switchMap((refreshed) => {
        if (refreshed) {
          return next(addToken(req));
        } else {
          authService.logout();
          return of();
        }
      }),
    );
  } else {
    return next(addToken(req)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return authService.refreshToken().pipe(
            switchMap((refreshed) => {
              if (refreshed) {
                return next(addToken(req));
              } else {
                authService.logout();
                return throwError(() => new Error('Session expired'));
              }
            }),
          );
        }
        return throwError(() => error);
      }),
    );
  }
};
