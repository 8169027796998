import { Routes } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoginGuard } from './common/guards/login.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./feature/login/login.routes'),
        canActivate: [LoginGuard],
      },
      {
        path: 'authenticate',
        loadChildren: () =>
          import('./feature/authenticate/authenticate.routes'),
      },
    ],
  },
  {
    path: 'app',
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./feature/dashboard/dashboard.routes'),
      },
      {
        path: 'wallet-list',
        loadChildren: () => import('./feature/wallet-list/wallet-list.routes'),
      },
      {
        path: 'wallet-connect',
        loadChildren: () =>
          import('./feature/wallet-connect/wallet-connect.routes'),
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/login' },
];
