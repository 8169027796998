@if (showNotification) {
  <div class="notification-backdrop" (click)="closeNotification()"></div>
  <div class="notification-overlay">
    <div class="notification-text font-regular">
      {{ message ?? '' | translate }}
    </div>
    <button
      class="meta_button_purple font-regular"
      (click)="closeNotification()">
      Ok
    </button>
  </div>
}
