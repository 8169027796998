import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InitSettingsComponent } from './feature/init-settings/init-settings.component';
import { RouterOutlet } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { NotificationsComponent } from './ui/notifications/notifications/notifications.component';
import { FaqComponent } from './feature/faq/faq/faq.component';
import { LoadingService } from './common/services/loading/loading.service';
import { LoaderComponent } from './feature/loader/loader/loader.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    InitSettingsComponent,
    RouterOutlet,
    AuthLayoutComponent,
    NotificationsComponent,
    FaqComponent,
    LoaderComponent,
    AsyncPipe,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(protected readonly loadingService: LoadingService) {}

  ngOnInit() {}
}
