import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Helper from '../../common/helpers/helper';

const API_URL = `${environment.apiUrl}/settings/locale/`;
const CACHE_PREFIX = 'TRANSLATE_';

export class LocalStorageTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const cacheKey = `${CACHE_PREFIX}${lang}`;
    const cache = Helper.getCacheItem<any>(cacheKey);

    if (cache && Helper.isValidCache(cache)) {
      return of(cache.data);
    }

    return this.http.get(`${API_URL}${lang}`).pipe(
      tap((response) => Helper.setCacheItem(cacheKey, response)),
      catchError(() => {
        if (cache) {
          return of(cache.data);
        }
        return of({});
      }),
    );
  }
}
